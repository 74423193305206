import React, { useState } from 'react';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { ThreeDotButton } from 'components/ThreeDotButton/ThreeDotButton';
import { useClassNames } from 'hooks/useClassNames';
import { useObservableState } from 'observable-hooks';
import { TitleRenameForm } from './components/TitleRenameForm/TitleRenameForm';
import { DetailsPanelContentTitleStyle as S } from './DetailsPanelContentTitle.style';
import { ReactComponent as Delete } from 'assets/svg/delete.svg';
import { ReactComponent as Pencil } from 'assets/svg/pencil.svg';
import { confirmDialogV2 } from 'services/confirmDialogV2';

export const DetailsPanelContentTitle: React.FC = () => {
  const [extended, setExtended] = useState(false);
  const { $selectedFilter, $renameMode, setRenameMode, deleteFilter } = useSavedFiltersModalBLoC();
  const selectedFilter = useObservableState($selectedFilter);
  const renameMode = useObservableState($renameMode);
  const titleClassNames = useClassNames({
    'is-extended': extended,
  });

  return renameMode ? (
    <TitleRenameForm />
  ) : (
    <S.Container>
      <S.Title onClick={() => setExtended((prev) => !prev)} className={titleClassNames}>
        {selectedFilter?.name}
      </S.Title>
      <S.Options>
        <ThreeDotButton
          menuItems={[
            {
              label: 'Rename',
              command: () => setRenameMode(true),
              icon: <Pencil />,
            },
            {
              separator: true,
            },
            {
              label: 'Delete',
              command: () => {
                confirmDialogV2({
                  title: 'Delete filter?',
                  description:
                    'Your saved filter will be removed from library. Are you sure you want to delete?',
                  acceptLabel: 'Yes, delete',
                  onAccept: () => deleteFilter(),
                });
              },
              icon: <Delete />,
              className: 'destructive',
            },
          ]}
        />
      </S.Options>
    </S.Container>
  );
};
