import { useFormValue } from 'hooks/useFormValue';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { useCreditsModalBLoC } from '../../CreditsModal.bloc';
import { CreditsModalActionButtonsStyle as S } from './CreditsModalActionButtons.style';
import { PButton } from 'components/prime/PButton/PButton';

export const CreditsModalActionButtons: React.FC = () => {
  const { closeModal, form, $loading, $mode, project } = useCreditsModalBLoC();
  const loading = useObservableState($loading);
  const mode = useObservableState($mode, '');
  const formState = useFormState(form);
  const amount = useFormValue(form, 'amount');
  return (
    <S.Container>
      <PButton severity="secondary" label="Cancel" onClick={() => closeModal()} disabled={loading} />
      {mode !== 'credit-request' && (
        <PButton
          type="submit"
          label={
            project?.type === 'au-data-project' || project?.type === 'summarize'
              ? 'Pay and continue'
              : 'Complete'
          }
          disabled={
            mode !== 'credit-spend' && mode !== 'credit-request-approval' && (!formState.isValid || !amount)
          }
          loading={loading}
        />
      )}
    </S.Container>
  );
};
