import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useObservableState } from 'observable-hooks';
import React from 'react';
import { useMapStepBLoC } from '../../../../../../MapStep.bloc';
import { MapStepColumnMessagesStyle as S } from './MapStepColumnMessages.style';
import { ReactComponent as SuccessIcon } from 'assets/svg/checkmark-filled.svg';

export const MapStepColumnMessages: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({
  column,
}) => {
  const { $dateFieldCount, getAlertsByColumn } = useMapStepBLoC();
  const dateFieldCount = useObservableState($dateFieldCount);
  const alerts = getAlertsByColumn(column, dateFieldCount || 0);

  return (
    <S.Container>
      {alerts.map((alert, i) => (
        <S.Content key={i}>
          {alert.isMatched ? (
            <S.SuccessBox>
              <S.IconContainer>
                <SuccessIcon />
              </S.IconContainer>
              <S.SuccessText>Matched</S.SuccessText>
            </S.SuccessBox>
          ) : (
            <S.Message
              severity={alert.severity}
              text={
                <S.MessageContent>
                  <S.MessageTitle>{alert.title}</S.MessageTitle>
                  <S.MessageText>{alert.text}</S.MessageText>
                </S.MessageContent>
              }
            />
          )}
        </S.Content>
      ))}
    </S.Container>
  );
};
