import { CreditsModal } from 'components/modals/CreditsModal/CreditsModal';
import { ImportModal } from 'components/modals/ImportModal/ImportModal';
import { SavedFiltersModal } from 'components/modals/SavedFiltersModal/SavedFiltersModal';
import { ReactFCGenericType, WithOptional } from 'types/UtilityTypes';

export const modals = {
  'import-modal': ImportModal,
  'credits-modal': CreditsModal,
  'saved-filters-modal': SavedFiltersModal,
};
export namespace ModalServiceTypes {
  export type ModalServiceBLoCState = {
    active: {
      [K in keyof typeof modals]?: ModalServiceTypes.ModalProps<K> | null;
    };
    prev?:
      | {
          [K in keyof typeof modals]?: ModalServiceTypes.ModalProps<K> | null;
        }
      | null;
  };
  export type ModalProps<T extends keyof typeof modals> = WithOptional<
    ReactFCGenericType<(typeof modals)[T]>,
    'onClose'
  >;
  export type ModalOpenFunc = <T extends keyof typeof modals, P extends ModalProps<T>>(
    type: T,
    payload?: ((prev: () => void) => P) | P
  ) => void;
  export type ModalCloseFunc = (type: keyof typeof modals) => void;
  export type ModalPrevFunc = (typeCurrent: keyof typeof modals) => void;
}
