import { useBLoC } from 'hooks/useBLoC';
import { useInitBloc } from 'hooks/useInitBloc';
import { BLoCParams, renderBlocChild, IBLoCInitialisable, BLoCWithModalsBase } from 'types/BLoCBase';
import React from 'react';
import { catchError, combineLatest, first, map, of, shareReplay, switchMap } from 'rxjs';
import { $get, $patch, $put } from 'services/api';
import { useObservableState } from 'observable-hooks';
import { TIMEZONES } from 'consts/timezones';
import { format } from 'date-fns';
import { toast } from 'services/toast';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { useImportModalCsvBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.bloc';
import { ReactComponent as ResponseDateIcon } from 'assets/svg/response-date.svg';
import { ReactComponent as TextCountedIcon } from 'assets/svg/text-counted.svg';
import { ReactComponent as CustomVariableIcon } from 'assets/svg/custom-variable.svg';
import {
  CUSTOM_VARIABLE_RESPONSE_TYPE_ID,
  DATE_RESPONSE_TYPE_ID,
  NPS_RESPONSE_TYPE_ID,
  TEXT_RESPONSE_TYPE_ID,
} from 'consts/import';
import { SurveyQuestion } from 'types/SurveyQuestions';
import { ImportModalTypes } from 'components/modals/ImportModal/ImportModal.types';
import { MessageProps } from 'primereact/message';
import { getUserStore } from 'stores/user.store';
import { MapStepStyle as S } from './MapStep.style';

const getDataTypesModalIds = async () => {
  try {
    const data = await localStorage.getItem('first-time-data-type-modal');
    const parse = JSON.parse(data || '');
    return parse && Array.isArray(parse) ? parse : [];
  } catch (err) {
    return [];
  }
};

const getColumnName = (column: ImportModalCsvTypes.IngestionColumn) =>
  (column.customName ? column.customName : column.metaName).trim();

type State = {
  columns: ImportModalCsvTypes.IngestionColumn[];
  loading?: boolean;
  draftData: {
    [key: string]: any;
  };
  customVariables: { id: string; name: string }[];
  questions: { id: string; name: string; type: string }[];
};

class BLoC
  extends BLoCWithModalsBase<
    State,
    {
      changeResponseDates: {
        id: number;
      };
      dataTypesModal: {};
    }
  >
  implements IBLoCInitialisable
{
  public $columns = this.$getState('columns');
  public $loading = this.$getState('loading');
  public $responseDataTypes = $get<{ id: number; name: string }[]>('response-data-types').pipe(
    catchError(() => []),
    shareReplay()
  );
  public $customVariables = this.$getState('customVariables');
  public $questions = this.$getState('questions');
  public $questionsText = this.$questions.pipe(map((list) => [...list.filter((a) => a?.type === 'text')]));
  public $questionsNps = this.$questions.pipe(map((list) => [...list.filter((a) => a?.type === 'nps')]));
  public $dateFieldCount = this.$columns.pipe(
    map(
      (cols) =>
        cols.filter((col) => col.responseDataTypeId === DATE_RESPONSE_TYPE_ID && !col.ignoreColumn).length
    )
  );

  public $selectedVars = this.$columns.pipe(
    map((columns) => {
      let questions: string[] = [];
      let variables: string[] = [];
      columns.forEach((col) => {
        if (
          [TEXT_RESPONSE_TYPE_ID, NPS_RESPONSE_TYPE_ID].includes(col.responseDataTypeId) &&
          col.questionId &&
          col.questionId !== 'new'
        )
          questions = [...questions, col.questionId];
        if (
          col.responseDataTypeId === CUSTOM_VARIABLE_RESPONSE_TYPE_ID &&
          col.customVariableId &&
          col.customVariableId !== 'new'
        )
          variables = [...variables, col.customVariableId];
      });
      return { questions, variables };
    })
  );

  public isNewColumn = (column: ImportModalCsvTypes.IngestionColumn) =>
    !!(this.currentIngestion?.draft || {})[column.id]?.isNewColumn;

  public isMatchedCustomVariable = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasCustomVars
      ? column.responseDataTypeId === CUSTOM_VARIABLE_RESPONSE_TYPE_ID && column.customVariableId
      : column.responseDataTypeId === CUSTOM_VARIABLE_RESPONSE_TYPE_ID;

  public isMatchedQuestion = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasQuestions
      ? column.responseDataTypeId === TEXT_RESPONSE_TYPE_ID && column.questionId
      : column.responseDataTypeId === TEXT_RESPONSE_TYPE_ID;

  public isMatchedNpsQuestion = (column: ImportModalCsvTypes.IngestionColumn) =>
    this.hasNpsQuestions
      ? column.responseDataTypeId === NPS_RESPONSE_TYPE_ID && column.questionId
      : column.responseDataTypeId === NPS_RESPONSE_TYPE_ID;

  public isMatched = (column: ImportModalCsvTypes.IngestionColumn) => {
    return (
      this.isEmptyProject ||
      this.isMatchedCustomVariable(column) ||
      this.isMatchedQuestion(column) ||
      this.isMatchedNpsQuestion(column) ||
      this.isNewColumn(column)
    );
  };

  public $errors = combineLatest([this.$columns, this.$dateFieldCount]).pipe(
    map(([columns, dateFieldCount]) => {
      let message = !dateFieldCount ? '- You must assign a response date to continue.' : '';
      let count = 0;
      let ids: number[] = [];

      if (!dateFieldCount) count = count + 1;

      if (dateFieldCount > 1) count = count + dateFieldCount;

      columns
        .filter((c) => !c.ignoreColumn)
        .forEach((column) => {
          if (this.existName(column)) {
            count = count + 1;
            ids = [...ids, column.id];
          }

          if (
            !this.isEmptyProject &&
            !this.isMatched(column) &&
            column.responseDataTypeId !== DATE_RESPONSE_TYPE_ID
          ) {
            count = count + 1;
            ids = [...ids, column.id];
          }
        });

      return {
        count,
        message,
        ids,
      };
    })
  );

  public showRenameAlert = (column: ImportModalCsvTypes.IngestionColumn) =>
    column.wasMappedColRenamed && !column.editing;

  public existName = (column: ImportModalCsvTypes.IngestionColumn) => {
    const columns = this.currentState('columns').filter((c) => !c.ignoreColumn && c.id !== column.id);
    const data = [...this.currentState('questions'), ...this.currentState('customVariables')].filter((d) =>
      [TEXT_RESPONSE_TYPE_ID, NPS_RESPONSE_TYPE_ID].includes(column.responseDataTypeId)
        ? d.id !== column.questionId
        : d.id !== column.customVariableId
    );
    return (
      columns.some((c) => getColumnName(c).trim() === getColumnName(column)) ||
      !!data.find((c) => c.name === getColumnName(column))
    );
  };

  public getAlertsByColumn = (column: ImportModalCsvTypes.IngestionColumn, dateFieldCount: number) => {
    let alerts: {
      title?: string;
      text?: string;
      severity?: MessageProps['severity'];
      isMatched?: boolean;
    }[] = [];
    const hasDateFieldDuplicated = dateFieldCount > 1;

    if (column.ignoreColumn) return alerts;

    if (this.existName(column)) {
      alerts.push({
        title: 'Name already used',
        text: 'This name already seems to exist. Please rename to something unique to continue.',
        severity: 'warn',
      });
    }

    if (column.responseDataTypeId === DATE_RESPONSE_TYPE_ID) {
      alerts.push({
        title: hasDateFieldDuplicated ? 'You can only have one response date' : 'This is your response date',
        text: hasDateFieldDuplicated
          ? 'We’ve detected multiple response dates in your file. Change the data type to Custom variable for all other columns to continue.'
          : 'You must have a response date. You can only have one response date per file.',
        severity: hasDateFieldDuplicated ? 'warn' : 'info',
      });
    }

    if (alerts.length) return alerts;

    if (!this.isEmptyProject) {
      if (this.isMatched(column) && !this.showRenameAlert(column)) {
        alerts.push({ isMatched: true });
      } else {
        if (column.wasMappedColRenamed) {
          alerts.push({
            title: 'Renamed in the previous upload',
            text: 'Confirm a column to merge with using the "Map to" dropdown below the column name on the left hand side, or select "Create new column".',
            severity: 'warn',
          });
        } else {
          alerts.push({
            title: 'No match found',
            text: 'Select an existing column to merge with using the "Map to" dropdown below the column name on the left hand side, or "Create new column".',
            severity: 'warn',
          });
        }
      }
    }
    return alerts;
  };

  public $hasDateField = this.$columns.pipe(
    map((cols) => !!cols.find((col) => col.responseDataTypeId === DATE_RESPONSE_TYPE_ID))
  );

  public $hasDateFieldDuplicated = this.$columns.pipe(
    map(
      (cols) =>
        cols.filter((col) => col.responseDataTypeId === DATE_RESPONSE_TYPE_ID && !col.ignoreColumn).length > 1
    )
  );

  public $nohasDateField = this.$columns.pipe(
    map(
      (cols) =>
        !cols.filter((col) => col.responseDataTypeId === DATE_RESPONSE_TYPE_ID && !col.ignoreColumn).length
    )
  );

  public get error() {
    return this.currentIngestion?.error;
  }

  public get timezones() {
    return TIMEZONES.map((t) => ({ id: t.value, name: `${t.name} (${t.value})` })).sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
  }

  public get currentDate() {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      date: format(new Date(), 'MM/dd/yyyy'),
    };
  }

  public $disableNextButton = combineLatest([this.$errors, this.$loading]).pipe(
    map(([errors, loading]) => !!errors.count || loading)
  );

  public openResponseDateModal = (id: number) => this._openModal('changeResponseDates', { id });

  public closeResponseDateModal = () => this._closeModal();

  public closeDataTypesModal = async () => {
    this._closeModal();
    const ids = await getDataTypesModalIds();
    if (this.userId && !ids.includes(this.userId))
      localStorage.setItem('first-time-data-type-modal', JSON.stringify([...ids, this.userId]));
  };

  constructor(
    public readonly currentIngestion: ImportModalCsvTypes.Ingestion | undefined,
    public readonly currentIngestionColumns: ImportModalCsvTypes.IngestionColumn[],
    public readonly setIngestion: (ingestion: ImportModalCsvTypes.Ingestion) => void,
    public readonly setIngestionColumns: (ingestion: ImportModalCsvTypes.IngestionColumn[]) => void,
    public readonly setSelectedColumnIds: (columns: number[]) => void,
    public readonly changeStep: (step: ImportModalCsvTypes.IngestionSteps) => void,
    public readonly isEmptyProject: boolean,
    public readonly userId?: number,
    public readonly project?: ImportModalTypes.Project
  ) {
    super({ columns: [], draftData: {}, customVariables: [], questions: [] });
  }

  public onInit = async () => {
    if (this.currentIngestion && this.currentIngestionColumns.length) {
      if (this.hasCustomVars) this.getCustomVariables();
      if (this.hasQuestions) this.getQuestions();
      this.formatData(this.currentIngestion, this.currentIngestionColumns);
    }
    const data = await getDataTypesModalIds();
    if (this.userId && !data.includes(this.userId)) this._openModal('dataTypesModal', {});
  };

  public get hasQuestions() {
    return !!this.project?.hasQuestions;
  }

  public get hasCustomVars() {
    return !!this.project?.hasCustomVars;
  }

  public get hasNpsQuestions() {
    return !!this.hasQuestions && !!this.currentState('questions').filter((a) => a?.type === 'nps').length;
  }

  public formatData = (
    ingestion: ImportModalCsvTypes.Ingestion,
    columns: ImportModalCsvTypes.IngestionColumn[]
  ) => {
    this.setStates({
      draftData: ingestion.draft,
      columns: columns
        .map((col) => ({
          ...col,
          questionId:
            [TEXT_RESPONSE_TYPE_ID, NPS_RESPONSE_TYPE_ID].includes(col.responseDataTypeId) &&
            this.isNewColumn(col)
              ? 'new'
              : col.questionId,
          customVariableId:
            col.responseDataTypeId === CUSTOM_VARIABLE_RESPONSE_TYPE_ID && this.isNewColumn(col)
              ? 'new'
              : col.customVariableId,
        }))
        .slice(),
      /**
       * @see https://thethinkingstudio.atlassian.net/browse/CE-10022
       */
      // .sort((a, b) =>
      //   a.responseDataTypeId === DATE_RESPONSE_TYPE_ID && b.responseDataTypeId !== DATE_RESPONSE_TYPE_ID
      //     ? -1
      //     : 1
      // )
      // .sort((a) => (!this.isMatched(a) && a.responseDataTypeId !== DATE_RESPONSE_TYPE_ID ? -1 : 1)),
    });
  };

  public getQuestions = () => {
    this.addSub(
      $get<SurveyQuestion[]>(`questions`, {
        projectId: this.project?.id,
      })
        .pipe(
          map((list) => [
            { id: 'new', name: 'Create new column', type: 'auto' },
            { id: 'divider', name: 'EXISTING COLUMNS', type: 'auto' },
            ...list
              .filter((a) => a?.type === 'text' || a?.type === 'nps')
              .map(({ cleanTitle, id, type }) => ({
                id,
                name: cleanTitle || '',
                type,
              })),
          ]),
          catchError(() => []),
          shareReplay()
        )
        .subscribe((data) => this.setState('questions', data))
    );
  };

  public getCustomVariables = () =>
    this.addSub(
      $get<{ id: string; name: string }[]>(`ingestions/${this.project?.id}/vars`)
        .pipe(
          map((list) => [
            { id: 'new', name: 'Create new column' },
            { id: 'divider', name: 'EXISTING COLUMNS' },
            ...list,
          ]),
          catchError(() => []),
          shareReplay()
        )
        .subscribe((data) => this.setState('customVariables', data))
    );

  public changeResponseDates = (id: number) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      return tempColumns.map((column) =>
        column.id === id
          ? { ...column, responseDataTypeId: DATE_RESPONSE_TYPE_ID, editing: null }
          : {
              ...column,
              editing: null,
              responseDataTypeId:
                column.responseDataTypeId === DATE_RESPONSE_TYPE_ID
                  ? CUSTOM_VARIABLE_RESPONSE_TYPE_ID
                  : column.responseDataTypeId,
            }
      );
    });
    this.closeResponseDateModal();
  };

  public showMapToDropdown = () => !this.isEmptyProject;

  public handleChangeResponseType = (id: number, value: number, hasDateField?: boolean) => {
    if (hasDateField && value === DATE_RESPONSE_TYPE_ID) {
      this.openResponseDateModal(id);
    } else {
      this.mutateState('columns', (prevState) => {
        let tempColumns = [...prevState];
        const i = tempColumns.findIndex((ing) => ing.id === id);
        tempColumns[i].responseDataTypeId = value;
        tempColumns[i].editing = null;
        return tempColumns;
      });
    }
  };

  public handleChangeIgnoreColumn = (id: number, value: boolean) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].ignoreColumn = !value;
      return tempColumns;
    });
  };

  public isDisabledCustomName = (column: ImportModalCsvTypes.IngestionColumn) =>
    column.responseDataTypeId === DATE_RESPONSE_TYPE_ID ||
    (!this.isEmptyProject &&
      (column.responseDataTypeId === TEXT_RESPONSE_TYPE_ID
        ? column.questionId !== 'new' && this.hasQuestions
        : column.responseDataTypeId === NPS_RESPONSE_TYPE_ID
        ? column.questionId !== 'new' && this.hasNpsQuestions
        : column.customVariableId !== 'new' && this.hasCustomVars));

  public handleChangeCustomName = (id: number, value: string) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].customName = value;
      return tempColumns;
    });
  };

  public handleChangeTimezone = (id: number, value: string) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.timezone = value;
      return tempColumns;
    });
  };

  public handleChangeMonthFirst = (id: number, value: boolean) => {
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].extraInfo.monthFirst = value;
      return tempColumns;
    });
  };

  public updateDraftId = (id: number, value: string) =>
    this.mutateState('draftData', (data) => ({
      ...data,
      [id]: {
        isNewColumn: value === 'new',
      },
    }));

  public getCustomVariableName = (id: string) =>
    this.currentState('customVariables').find((item) => item.id === id)?.name;

  public handleChangeCustomVariableId = (id: number, value: string, name: string) => {
    this.updateDraftId(id, value);
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].customVariableId = value;
      tempColumns[i].editing = value;
      tempColumns[i].customName = value === 'new' ? '' : name;
      tempColumns[i].questionId = null;
      return tempColumns;
    });
  };

  public handleChangeQuestionId = (id: number, value: string, name: string) => {
    this.updateDraftId(id, value);
    this.mutateState('columns', (prevState) => {
      let tempColumns = [...prevState];
      const i = tempColumns.findIndex((ing) => ing.id === id);
      tempColumns[i].questionId = value;
      tempColumns[i].editing = value;
      tempColumns[i].customName = value === 'new' ? '' : name;
      tempColumns[i].customVariableId = null;
      return tempColumns;
    });
  };

  public getIngestion = (id: number) => $get<ImportModalCsvTypes.Ingestion>(`ingestions/${id}`);

  public updateDraftData = () => {
    const data = this.currentState('draftData') || {};
    return !!Object.keys(data).length
      ? $patch<ImportModalCsvTypes.Ingestion>(`ingestions/${this.currentIngestion?.id}/draft`, {
          draft: data,
        }).pipe(catchError(() => of(null)))
      : of(null);
  };

  public commitChanges = (next = true, onSuccess?: () => void) => {
    const columns = this.currentState('columns');
    this.setState('loading', true);
    let tempColumns: ImportModalCsvTypes.ColumnToMap[] = [];
    let formattedColumns = columns.slice().map((col) => {
      const { id, customName, ignoreColumn, responseDataTypeId, extraInfo, customVariableId, questionId } =
        col;
      const tempCol = {
        id,
        customName,
        ignoreColumn,
        responseDataTypeId,
        extraInfo: {
          ...extraInfo,
          ...(responseDataTypeId === DATE_RESPONSE_TYPE_ID && {
            timezone: extraInfo.timezone || this.currentDate.timezone,
          }),
        },
        ...(!this.isEmptyProject &&
          responseDataTypeId === CUSTOM_VARIABLE_RESPONSE_TYPE_ID && {
            customVariableId: customVariableId === 'new' ? null : customVariableId,
          }),
        ...(!this.isEmptyProject &&
          [TEXT_RESPONSE_TYPE_ID, NPS_RESPONSE_TYPE_ID].includes(responseDataTypeId) && {
            questionId: questionId === 'new' ? null : questionId,
          }),
      };

      tempColumns = [...tempColumns, tempCol];

      return {
        ...col,
        ...tempCol,
      };
    });

    this.updateDraftData()
      .pipe(
        switchMap(() =>
          $put<ImportModalCsvTypes.Ingestion>(`ingestions/${this.currentIngestion?.id}/map-columns`, {
            mapping: tempColumns,
            goToNextStep: next,
          })
        )
      )
      .subscribe({
        next: (ingestion) => {
          this.setIngestionColumns(formattedColumns);
          this.setIngestion(ingestion);
          this.changeStep('columns_mapped');
          this.setSelectedColumnIds([]);
          onSuccess?.();
        },
        error: (err) => {
          this.setState('loading', false);
          const msg = (err?.message as string) || '';
          const isLongCustomVarNameError = msg.includes('Custom var name') && msg.includes('is too long');
          toast({
            severity: 'error',
            summary: isLongCustomVarNameError
              ? 'Custom variable name is too long'
              : msg || 'An error has ocurred. Try again later.',
            detail: isLongCustomVarNameError
              ? msg.replaceAll(/custom var name | is too long/gim, '')
              : undefined,
          });
        },
      });
  };

  public back = () => this.changeStep(null);

  public selectIcon = (responseDataTypeId: number) => {
    switch (responseDataTypeId) {
      case 1:
        return <CustomVariableIcon />;
      case 2:
        return <TextCountedIcon />;
      case 3:
        return <ResponseDateIcon />;
      case 4:
        return <S.ScaleIcon />;
      default:
        return null;
    }
  };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const useMapStepBLoC = () => useBLoC<BLoC>(Context);

export const MapStepBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const {
    $currentIngestion,
    $currentIngestionColumns,
    setIngestion,
    setIngestionColumns,
    setSelectedColumnIds,
    changeStep,
    isEmptyProject,
    project,
  } = useImportModalCsvBLoC();

  const ingestion = useObservableState($currentIngestion.pipe(first()));
  const columns = useObservableState($currentIngestionColumns.pipe(first()), []);
  const user = useObservableState(getUserStore().$user);

  const bloc = useInitBloc(
    () =>
      new BLoC(
        ingestion,
        columns,
        setIngestion,
        setIngestionColumns,
        setSelectedColumnIds,
        changeStep,
        isEmptyProject,
        user?.id,
        project
      ),
    [ingestion, columns]
  );

  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
