import styled, { createGlobalStyle } from 'styled-components';
import { DataTable } from 'primereact/datatable';
import { colours } from 'styles/styles';
import { PaginatorBaseStyle } from '../PPaginator/PPaginator.style';

/**
 * @deprecated Use PDataTableV2 instead
 */
export const PDataTableStyle = {
  DataTable: styled(DataTable)`
    border: 1px solid ${colours.neutralGrey03};
    .p-datatable-table {
      & > .p-datatable-thead {
        & > tr > th {
          font-weight: 600;
          background-color: ${colours.neutralGreyBg};
          color: ${colours.neutralBody};
          font-size: 12px;
          font-family: 'SuisseIntl', sans-serif;
          font-weight: 400;
          text-transform: uppercase;
          border-bottom: 1px solid ${colours.neutralGrey03};
        }
      }
    }

    & > .p-datatable-loading-overlay.p-component-overlay {
      opacity: 0.2;
    }

    & > .p-paginator {
      ${PaginatorBaseStyle}
      padding: 8px 12px;
    }

    & .p-datatable-wrapper {
      overflow-x: overlay;
      overflow-y: overlay;
    }
    & .p-datatable-thead > tr > th {
      border: none;
      background-color: ${colours.neutralGrey01};
      padding: 0 12px;
      height: 56px;
      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      &.border-right {
        border-right: 1px solid ${colours.neutralGrey03};
      }
      &.border-left {
        border-left: 1px solid ${colours.neutralGrey03};
      }

      &.table-overflow-title .p-column-header-content {
        max-width: 0px;
        overflow: visible;
      }
      &.table-overflow-title.p-align-right {
        position: relative;
        .p-column-header-content {
          margin-left: auto;
        }
      }
    }
    & .p-datatable-tbody {
      & > tr.p-datatable-row-expansion > td {
        padding: 0px;
      }
      .p-row-toggler.p-link {
        width: 24px;
        height: 24px;
        & .p-row-toggler-icon.pi-chevron-right {
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            font-size: 13px;
          }
        }
      }

      & > tr > td {
        padding: 0 12px;
        height: 56px;
        border-color: ${colours.neutralGrey02};
        color: ${colours.neutralCharcoal};
        &.border-right {
          border-right: 1px solid ${colours.neutralGrey03};
        }
        &.border-left {
          border-left: 1px solid ${colours.neutralGrey03};
        }
        &.no-padding {
          padding: 0px;
        }
      }
    }
    &.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover,
    &.p-datatable-selectable
      .p-datatable-tbody
      > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
      background: none;
      background-color: ${colours.neutralGrey02};
    }
  `,
  Global: createGlobalStyle`
    div.p-dropdown-panel.p-component {
      .p-dropdown-item {
        height: 32px;

        color: ${colours.neutralLightCharcoal};
        display: flex;
        align-items: center;
        &.p-highlight {
          background: ${colours.neutralGrey03};
          color: ${colours.neutralCharcoal};
        }
      }
    }
  `,
};
