import { useMapStepBLoC } from 'components/modals/ImportModal/components/ImportModalCsv/components/ImportModalCsvContent/components/MapStep/MapStep.bloc';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnNameStyle as S } from './ColumnName.style';

export const ColumnName: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({ column }) => {
  const [editMode, setEditMode] = useState(true);
  const inputRef = useRef<any>(null);
  const { handleChangeCustomName, isDisabledCustomName } = useMapStepBLoC();

  const text = column.customName || column.metaName || '';

  const showInput = () => setEditMode(false);

  const showText = () => {
    setEditMode(true);
    handleChangeCustomName(column.id, text.trim());
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        const scrollHeight = inputRef.current.scrollHeight;
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${scrollHeight}px`;
      }
    });
  }, []);

  return (
    <S.Container>
      <S.Title>COLUMN NAME</S.Title>
      <S.Input
        ref={inputRef}
        value={text}
        maxLength={255}
        minLength={3}
        viewOnly={editMode}
        onClick={showInput}
        onChange={(e: any) => {
          if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            const scrollHeight = e.target.scrollHeight;
            inputRef.current.style.height = `${scrollHeight}px`;
          }
          handleChangeCustomName(column.id, e.target.value.replaceAll('\n', ''));
        }}
        onKeyDown={(e) => e.key === 'Enter' && (e.target as HTMLInputElement).blur()}
        onBlur={showText}
        placeholder="Rename column ID here (optional)"
        $ignoreColumn={column.ignoreColumn}
        $inactive={isDisabledCustomName(column)}
        disabled={column.ignoreColumn || isDisabledCustomName(column)}
      />
    </S.Container>
  );
};
