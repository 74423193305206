import React from 'react';
import { Spinner } from 'components/Spinner/Spinner';
import { DetailsPanelContentLoadingStyle as S } from './DetailsPanelContentLoading.style';

export const DetailsPanelContentLoading: React.FC = () => {
  return (
    <S.Container>
      <Spinner color="neutral900" size={36} />
      <S.Text>Loading filter conditions</S.Text>
    </S.Container>
  );
};
