import React, { useMemo } from 'react';
import { FormattedDate } from 'components/FormattedDate/FormattedDate';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';
import { DetailsPanelContentMetaStyle as S } from './DetailsPanelContentMeta.style';

export const DetailsPanelContentMeta: React.FC = () => {
  const { $selectedFilter, $projects } = useSavedFiltersModalBLoC();
  const selectedFilter = useObservableState($selectedFilter);
  const projects = useObservableState($projects);
  const filterProjectName = useMemo(
    () => projects?.find((p) => p.id === selectedFilter?.projectId)?.name,
    [selectedFilter, projects]
  );

  return (
    <S.Container>
      {filterProjectName && <li>{filterProjectName}</li>}
      {selectedFilter?.createdAt && (
        <li>
          <FormattedDate date={selectedFilter?.createdAt} format="EEE, d MMMM yyyy" />
        </li>
      )}
      {selectedFilter?.createdBy && <li>{selectedFilter?.createdBy}</li>}
    </S.Container>
  );
};
