(function () {
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;

  history.pushState = function (...args) {
    originalPushState.apply(this, args);
    window.dispatchEvent(new Event('historychange'));
    return;
  };

  history.replaceState = function (...args) {
    originalReplaceState.apply(this, args);
    window.dispatchEvent(new Event('historychange'));
    return;
  };

  window.addEventListener('popstate', function () {
    window.dispatchEvent(new Event('historychange'));
  });
})();
