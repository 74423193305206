import React from 'react';
import { MapStepDataTypesModalStyle as S } from './MapStepDataTypesModal.style';
import { useMapStepBLoC } from '../../MapStep.bloc';
import { useObservableState } from 'observable-hooks';
import { MapStepDataTypeData } from '../MapStepDataTypeData/MapStepDataTypeData';

export const MapStepDataTypesModal: React.FC = () => {
  const { $getModal, closeDataTypesModal } = useMapStepBLoC();
  const modal = useObservableState($getModal('dataTypesModal'));
  return modal ? (
    <S.Dialog visible={true} onHide={closeDataTypesModal} header="What are data types?" size="large">
      <MapStepDataTypeData type="modal" />
    </S.Dialog>
  ) : null;
};
