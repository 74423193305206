import React, { useCallback } from 'react';
import { useObservableState } from 'observable-hooks';
import { useMapStepBLoC } from '../../../../../../../../MapStep.bloc';
import { ColumnTypeStyle as S } from './ColumnType.style';
import { ImportModalCsvTypes } from 'components/modals/ImportModal/components/ImportModalCsv/ImportModalCsv.types';
import { ColumnTypeDropdownItemData } from './components/ColumnTypeDropdownItemData/ColumnTypeDropdownItemData';

export const ColumnType: React.FC<{ column: ImportModalCsvTypes.IngestionColumn }> = ({ column }) => {
  const { $responseDataTypes, handleChangeResponseType, $hasDateField, selectIcon } = useMapStepBLoC();
  const responseDataTypes = useObservableState($responseDataTypes, []);
  const hasDateField = useObservableState($hasDateField, false);

  const getIcon = useCallback(() => {
    return selectIcon(column.responseDataTypeId);
  }, [column, selectIcon]);

  return (
    <S.Container>
      <S.CustomSelector
        value={column.responseDataTypeId}
        options={responseDataTypes}
        optionValue="id"
        optionLabel="name"
        onChange={(e) => handleChangeResponseType(column.id, e.target.value, hasDateField)}
        itemTemplate={(item) => <ColumnTypeDropdownItemData column={item} name={item?.name} />}
        disabled={column.ignoreColumn}
      />
      <S.IconContainer disabled={column.ignoreColumn}>{getIcon()}</S.IconContainer>
    </S.Container>
  );
};
