import { BLoCBase } from 'types/BLoCBase';
import * as hubspotService from 'services/hubspot';
import { getUserStore } from './user.store';
import { combineLatest, first, switchMap } from 'rxjs';
import TagManager from 'react-gtm-module';
import { getCustomerStore } from './customer.store';
import { Segment } from 'services/segment';
import { getBillingPlanStore } from './billingPlan.store';

type GTMEvent = {
  event?: string;
  filters?: string;
  form?: string;
  sort?: string;
  path?: string;
  customerId?: string;
  customerName?: string;
  userId?: string;
  perPageNumber?: number;
  pages?: number;
  pageNumber?: number;
  activityType?: string;
  activityId?: string | number | null;
  value?: string | number;
  feedback?: string;
  total?: number;
  projectId?: string | null;
  projectType?: string;
  data?: string;
};

const tagManagerArgs = {
  gtmId: import.meta.env.REACT_APP_GTM_ID!,
};

class AnalyticsStore extends BLoCBase<{ enabled: boolean }> {
  private readonly $enabled = this.$getState('enabled');

  private pageViewTrack = () => {
    this.addSub(
      getUserStore().$onboardingStatus.subscribe((onboardingStatus) => {
        const trackingEnabled =
          onboardingStatus === 'email-verified' ||
          ['onboarded', 'profile-complete'].includes(onboardingStatus || '');
        if (trackingEnabled) {
          this.pageView();
          this.setState('enabled', true);
        }
      }),
      'page-event-tracker'
    );
  };

  constructor() {
    super({ enabled: false });
    if (this.currentState('enabled')) return;
    setTimeout(() => {
      this.pageViewTrack();
    });
    window.addEventListener('locationchange', (e) => {
      this.pageViewTrack();
      return e;
    });
    setTimeout(() => {
      import.meta.env.PROD &&
        TagManager.initialize({
          ...tagManagerArgs,
          dataLayer: {
            path: window.location.pathname,
          },
        });
      this.addSub(
        this.$enabled
          .pipe(
            first((enabled) => !!enabled),
            switchMap(() =>
              combineLatest([
                getUserStore().$user.pipe(first((user) => !!user)),
                getCustomerStore().$customer.pipe(first((customer) => !!customer)),
                getBillingPlanStore().$currentPlan.pipe(first()),
              ])
            )
          )
          .subscribe(([user, customer, plan]) => {
            if (!user?.isYabbleOwner) {
              hubspotService.initAndIdentify({
                email: user?.email,
                id: user?.id,
              });
              Segment.init();
              user &&
                window.analytics.identify(user.id, {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                  billingStatus: customer?.billingStatus,
                  created_at: user.createdAt
                    ? Math.floor(new Date(user.createdAt).getTime() / 1000)
                    : undefined,
                  company: {
                    id: customer?.id,
                    name: customer?.name,
                    country: customer?.countryName,
                    plan: plan?.name,
                    planId: plan?.id,
                    tier: plan?.metadata?.planTier,
                    createdAt: customer?.createdAt,
                  },
                } as any);

              setTimeout(() => {
                (window as any).Intercom?.('boot', {
                  name: `${user?.firstName} ${user?.lastName}`,
                  email: user?.email,
                  created_at: user?.createdAt
                    ? Math.floor(new Date(user.createdAt).getTime() / 1000)
                    : undefined,
                  user_id: user?.id,
                });
              }, 3000);
            }
            this.pageView();
          }),
        'initialBoot'
      );
    });
  }

  public sendEvent = (eventName: string, meta: Record<string, unknown> = {}) => {
    if (!this.currentState('enabled')) return;

    // Format {eventName} to send with the {meta} to analytics providers
    window.analytics.track(eventName, meta);
  };

  public pageView = (pathname?: string) => {
    if (!this.currentState('enabled')) return;

    window?.analytics?.page && window.analytics.page();
    // Track pageView with the different analytics providers
    hubspotService.pageView(pathname);
  };

  public sendGTMEvent = (event: GTMEvent) => {
    this.addSub(
      combineLatest([
        getUserStore().$user.pipe(first()),
        getCustomerStore().$customer.pipe(first()),
      ]).subscribe(([user, customer]) => {
        const eventTosend: GTMEvent = {
          path: window.location.pathname,
          ...(customer?.id && { customerId: customer?.id }),
          ...(customer?.name && { customerName: customer?.name }),
          ...(user?.id && { userId: user?.id as any }),
          ...event,
        };
        TagManager.dataLayer({
          dataLayer: eventTosend,
        });
      })
    );
  };
}

const store: Readonly<AnalyticsStore> = Object.freeze(new AnalyticsStore());
export const getAnalyticsStore = () => {
  return store;
};
