import React from 'react';
import { AmountInput } from 'components/AmountInput/AmountInput';
import { useCreditsModalBLoC } from 'components/modals/CreditsModal/CreditsModal.bloc';
import { CreditsModalTopupFormAmountInputStyle as S } from './CreditsModalTopupFormAmountInput.style';
import { useObservableState } from 'observable-hooks';
import { getBillingPlanStore } from 'stores/billingPlan.store';
import { map } from 'rxjs';

export const CreditsModalTopupFormAmountInput: React.FC = () => {
  const { form, project } = useCreditsModalBLoC();
  const availableCredits = useObservableState(
    getBillingPlanStore().$creditsBalance.pipe(map((bal) => bal || 0)),
    0
  );

  // const currentPlan = useObservableState(getBillingPlanStore().$currentPlan);
  // const step = useMemo(
  //   () =>
  //     ({
  //       '1': 1000,
  //       '2': 2500,
  //       '3': 10000,
  //     }[currentPlan?.metadata.planTier || '3'] || 100),
  //   [currentPlan]
  // );

  return (
    <S.FormField
      form={form}
      controler={{
        name: 'amount',
        rules: {
          required: 'Amount is required',
          max: 999999,
          min: {
            value: project && project.amount - availableCredits > 0 ? project.amount - availableCredits : 1,
            message: 'Must be above minimum top-up amount',
          },
        },
      }}
    >
      {({ field, fieldState }) => (
        <S.Container>
          <p>Enter top-up amount</p>
          <AmountInput
            {...field}
            onChange={(e) => field.onChange(e.value)}
            invalid={fieldState.invalid}
            step={100}
            max={999999}
            min={0}
          />
        </S.Container>
      )}
    </S.FormField>
  );
};
