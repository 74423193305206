import React from 'react';
import { ImportModalFooterStyle as S } from './ImportModalFooter.style';
import { PButton } from 'components/prime/PButton/PButton';

export const ImportModalFooter: React.FC<{
  cancelFn?: () => void;
  nextFn?: () => void;
  onBack?: () => void;
  saveAndCloseFn?: () => void;
  disabled?: boolean;
  loading?: boolean;
  successLabel?: string;
  cancelLabel?: string;
  hideDivider?: boolean;
  hideSuccessButton?: boolean;
}> = ({
  cancelFn,
  onBack,
  saveAndCloseFn,
  nextFn,
  disabled,
  loading,
  successLabel,
  hideDivider,
  hideSuccessButton,
  cancelLabel,
}) => {
  return (
    <S.Container onClick={(e: any) => e.stopPropagation()} {...{ hideDivider }}>
      <S.ButtonsContainer hasBack={!!onBack}>
        {!!onBack && <PButton label="Back" onClick={onBack} severity="ghost" />}
        <S.RightButtons>
          {cancelFn && (
            <PButton severity="secondary" label={cancelLabel || 'Close'} onClick={() => cancelFn()} />
          )}
          {saveAndCloseFn && (
            <PButton severity="secondary" label="Save and close" onClick={() => saveAndCloseFn()} />
          )}
          {!hideSuccessButton && (
            <PButton
              type={nextFn ? 'button' : 'submit'}
              label={successLabel || 'Next'}
              {...{ disabled, loading }}
              onClick={nextFn}
            />
          )}
        </S.RightButtons>
      </S.ButtonsContainer>
    </S.Container>
  );
};
