import React, { ChangeEvent } from 'react';
import { PInputTextProps } from 'components/prime/PInputText/PInputText';
import { SearchStyle as S } from './Search.style';
import { ReactComponent as SearchMdLine } from 'assets/svg/search-md-line.svg';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';

export const Search = React.forwardRef<
  React.ForwardRefExoticComponent<PInputTextProps & React.RefAttributes<HTMLInputElement>>,
  Omit<PInputTextProps, 'onChange'> & {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    debounceTime?: number;
  }
>((props, ref) => {
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [innerVal, setInnerVal] = React.useState(props.value || '');
  const { debounceTime, onChange } = props;
  const debounceEnabled = typeof debounceTime === 'number';

  const innerOnChange = React.useCallback(
    (e: any) => {
      if (debounceEnabled) {
        setInnerVal(e.target.value);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(
          () => onChange?.({ target: { value: e.target.value } } as any),
          debounceTime
        );
      } else {
        onChange?.(e);
      }
    },
    [debounceEnabled, onChange, debounceTime]
  );

  return (
    <S.InputText
      placeholder="Search"
      maxLength={255}
      {...props}
      value={debounceEnabled ? innerVal : props.value}
      onChange={(e) => innerOnChange(e)}
      leftIcon={<SearchMdLine />}
      rightIcon={
        (debounceEnabled && innerVal) || props.value ? (
          <PButtonIcon
            size="xs"
            shape="squircle"
            icon="xLine"
            onClick={(e: any) => {
              if (onChange) {
                const el: HTMLInputElement = e.target as any;
                el.value = '';
                innerOnChange(e as any);
                el.blur();
              }
            }}
            disabled={props.disabled}
          />
        ) : null
      }
      ref={ref as any}
      autoComplete="off"
    />
  );
});
