import React from 'react';
import { SavedFiltersModalTypes } from 'components/modals/SavedFiltersModal/SavedFiltersModal.types';
import { FiltersTableFilterItemStyle as S } from './FiltersTableFilterItem.style';
import { useSavedFiltersModalBLoC } from 'components/modals/SavedFiltersModal/SavedFiltersModal.bloc';
import { useObservableState } from 'observable-hooks';
import { useClassNames } from 'hooks/useClassNames';

export const FiltersTableFilterItem: React.FC<Pick<SavedFiltersModalTypes.SavedFilter, 'id' | 'name'>> = ({
  id,
  name,
}) => {
  const { $selectedFilterId, selectFilter } = useSavedFiltersModalBLoC();
  const selectedId = useObservableState($selectedFilterId);
  const classNames = useClassNames({
    'is-selected': selectedId === id,
  });

  return (
    <S.Container className={classNames} onClick={() => selectFilter(id)}>
      <p>{name}</p>
    </S.Container>
  );
};
